<template>
  <div style="height: 100%; background: #fff">
    <el-container>
      <div class="left-content">
        <head-layout :head-title="!readOnly ? '上传列表' : '附件预览'" :head-btn-options="leftBtnOptions">
        </head-layout>
        <div
          style="padding: 14px 12px; background: #ffffff; text-align: center">
          <!--          <el-upload-->
          <!--              class="upload-demo"-->
          <!--              action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km"-->
          <!--              :show-file-list="false"-->
          <!--              :on-success="handleAvatarSuccess"-->
          <!--              :before-upload="beforeUpload"-->
          <!--              ref="uploadRef"-->
          <!--              :headers="headers"-->
          <!--          >-->
          <!--            <el-button size="small" type="primary" icon="el-icon-upload"-->
          <!--            >点击上传-->
          <!--            </el-button-->
          <!--            >-->
          <!--          </el-upload>-->
          <SingleFIleUpload ref="singleFIleUpload" :readOnly="readOnly" @fileIds="handleAvatarSuccess" accept=""
                            fileSize="2000"
                            @handleRemove="handleRemove"></SingleFIleUpload>
        </div>
        <!--        <div class="img-container">-->
        <!--          <div-->
        <!--              v-for="item in treeData"-->
        <!--              :key="item.id"-->
        <!--              class="img-content"-->
        <!--              @click="getNodeClick(item)"-->
        <!--          >-->
        <!--            <div class="img-item" :class="item.isActive ? 'isActive' : ''">-->
        <!--              <img-->
        <!--                  v-if="-->
        <!--                  item.extension == 'jpg' ||-->
        <!--                  item.extension == 'png' ||-->
        <!--                  item.extension == 'image/jpeg' ||-->
        <!--                  item.extension == 'image/png'-->
        <!--                "-->
        <!--                  :src="item.link"-->
        <!--                  alt=""-->
        <!--              />-->
        <!--              <img v-else src="@/assets/images/dashboard-nodata.png" alt=""/>-->
        <!--              <i-->
        <!--                  v-if="!readOnly"-->
        <!--                  class="el-icon-circle-close delete-icon"-->
        <!--                  @click.stop="handleRemove(item)"-->
        <!--              ></i>-->
        <!--            </div>-->
        <!--            <video ref="video" id="myVideo" :src="videoUrl" crossOrigin="anonymous" style="display: none"></video>-->
        <!--            <canvas id="myCanvas" style="display: none" crossOrigin="anonymous"></canvas>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
      <el-container style="padding-bottom: 80px">
        <el-main style="background: #ffffff">
          <head-layout
            @head-summary="headSummary"
            @head-save="headSave"
            @head-submit="headSubmit"
            @head-back="headBack"
            :head-btn-options="headBtnOptions"
            head-title="知识上传"
          >
          </head-layout>
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="100px"
            style="padding: 12px"
          >
            <el-form-item label="文件名称" prop="fileName">
              <el-input
                type="text"
                v-model="form.fileName"
                placeholder="请输入文件名称"
                :disabled="readOnly"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item label="文件标签" prop="fileTag">
              <el-tag
                :key="tag"
                v-for="tag in form.fileTag"
                :closable="!readOnly"
                :disable-transitions="false"
                @close="handleClose(tag)"
              >
                {{ tag }}
              </el-tag>
              <el-input
                class="input-new-tag"
                v-if="inputVisible"
                v-model="inputValue"
                ref="saveTagInput"
                size="small"
                maxlength="50"
                @keyup.enter.native="handleInputConfirm"
                @blur="handleInputConfirm"
              >
              </el-input>
              <el-button
                v-if="!readOnly && !inputVisible"
                class="button-new-tag"
                size="small"
                @click="showInput"
              >新增标签
              </el-button
              >
              <el-button
                v-if="!readOnly && !inputVisible"
                class="button-new-tag"
                icon="robot"
                style="margin-left: 24px;padding: 5px 15px 8px 8px;"
                @click="headSummary"
              >智能总结
              </el-button>
              <el-tooltip
                class="item"
                effect="dark"
                content="智能总结功能可帮助您根据文件内容智能生成文件标签和文件摘要，可能需要较长时间，请耐心等待"
                placement="right-start"
              >
                <i
                  class="el-icon-question"
                  style="margin-left: 10px; font-size: 20px"
                ></i>
              </el-tooltip>
            </el-form-item>
            <el-form-item label="文件封面" prop="fileCover">
              <!--              <SingleFIleUpload ref='SingleFIleUpload' @fileIds="fileIds" accept=".jpeg,.jpg,.gif,.png" fileSize="20" :echo = "echo"></SingleFIleUpload>-->
              <el-upload
                :class="{ uoloadSty: showImg, disUoloadSty: noneUploadImg }"
                action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                :headers="headers"
                list-type="picture-card"
                :file-list="fileList"
                file="file"
                :on-remove="handleDeleteImgRemove"
                :on-success="handleFileCoverSuccess"
                :on-change="uploadImgChange"
                :before-upload="beforeAvatarUpload"
                accept=".jpeg,.jpg,.gif,.png"
                :limit="limitCountImg"
              >
                <i class="el-icon-plus"></i>
                <div
                  slot="file"
                  slot-scope="{ file }"
                  style="width: 100%; height: 100%"
                >
                  <img
                    class="el-upload-list__item-thumbnail"
                    :src="form.fileCover"
                    alt=""
                  />
                  <span
                    class="el-upload-list__item-actions"
                    v-if="!readOnly"
                  >
                    <span
                      class="el-upload-list__item-delete"
                      @click="handleDeleteImgRemove"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="知识分类" prop="knowledgeType">
              <avue-input-tree :props="inputTreeProps" default-expand-all v-model="form.knowledgeType"
                               placeholder="请选择"
                               :dic="dic" :disabled="readOnly" @change="chooseKnowledge"
                               style="width: 30% !important;"></avue-input-tree>
            </el-form-item>
            <el-form-item label="文件分类" prop="fileType" v-if="showClassification">
              <knowledge-classification
                ref="knowledgeClass"
                :selectedTagId="form.fileTypeId"
                :showTagContent="!readOnly"
                :view="!readOnly"
                :isCheck="false"
                @select-tag="selectTag"
              ></knowledge-classification>
            </el-form-item>
            <el-form-item label="适用范围1" prop="scopeDept">
              <avue-input-tree :props="deptProps" default-expand-all v-model="form.scopeDept" placeholder="请选择"
                               :dic="deptDic" :disabled="readOnly" style="width: 30% !important;"></avue-input-tree>
            </el-form-item>
            <el-form-item v-if="form.scopeApplication == '1'" prop="deptIdList">
              <el-tree
                ref="deptTree"
                :data="deptTreeData"
                show-checkbox
                node-key="id"
                @check-change="handleCheckChange"
                :default-expand-all="true"
                :default-checked-keys="defaultChecked"
                :props="defaultProps"
              >
              </el-tree>
            </el-form-item>
            <el-form-item label="文件摘要" prop="fileDesc">
              <el-input
                type="textarea"
                v-model="form.fileDesc"
                :disabled="readOnly"
                maxlength="3000"
                show-word-limit
                :rows="10"
              ></el-input>
            </el-form-item>
          </el-form>
        </el-main>
      </el-container>
    </el-container>

    <common-dialog
      v-if="showFileDialog"
      width="70%"
      dialogTitle="附件预览"
      :showConfirmBtn="false"
      @cancel="showFileDialog = false">
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
    </common-dialog>

    <video ref="video" id="myVideo" :src="videoUrl" crossOrigin="anonymous" style="display: none"></video>
    <canvas id="myCanvas" style="display: none" crossOrigin="anonymous"></canvas>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CommonTree from "@/views/business/knowledge/components/commonTree";
import HeadLayout from "@/views/components/layout/head-layout";
import knowledgeClassification from "@/views/business/knowledge/components/knowledge-classification";
import {
  getDetail,
  getKnowledgeClassifyTree, intelligentSummary, removeSafetyTrainingTree,
  removeUploadFile,
  saveUpload
} from "@/api/knowledgeClassifcation/knowledge";
import draft from "@/views/plugin/workflow/mixins/draft";
import WfUserSelect from "@/views/plugin/workflow/process/components/user-select";
import WfExamineForm from "@/views/plugin/workflow/process/components/examForm";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import {processList as getProcessList} from "@/api/plugin/workflow/process";
import {getToken} from "@/util/auth";
import {selectChildren, selectParentTree} from "@/api/system/dept";
import website from "@/config/website";
import {retentionTime, trainGroupScenDetail, trainGroupSceneSubmit} from "@/api/onTheJobTraining/training";
import SingleFIleUpload from "@/components/file-upload/SingleFIleUpload";
import {generateCover} from "@/api/resource/attach"

import {platform} from "@/api/system/user";
import {list} from "@/api/system/params"
import CommonDialog from "@/components/CommonDialog";

export default {
  components: {
    HeadLayout,
    CommonTree,
    knowledgeClassification,
    WfUserSelect,
    WfExamineForm,
    SingleFIleUpload,
    CommonDialog,
  },
  data() {
    return {
      fileOne: {},
      fileSuffixType: "",
      treeData: [],
      videoUrl: "",
      readOnly: false,
      processTemplateKey: "knowledgeUpload",
      processId: "",
      process: {},
      page: {
        pageSize: 999,
        currentPage: 1,
        total: 0,
      },
      submitLoading: false,
      //   标签
      inputVisible: false,
      inputValue: "",
      form: {
        fileName: "",
        fileCode: "",
        fileTag: [],
        fileCover: "",
        knowledgeType: "",
        fileType: "",
        fileTypeId: "",
        scopeApplication: "",
        scopeDept: [],
        fileDesc: "",
        isTrendingSearch: 0,
        isPublish: 1,
        status: 1,
        deptIdList: [],
      },
      rules: {
        fileName: [
          {required: true, message: "请输入文件名称", trigger: "change"},
        ],
        fileTag: [
          {required: true, message: "请新增文件标签", trigger: "blur"},
        ],
        fileCover: [
          {required: true, message: "请选择文件封面", trigger: "change"},
        ],
        knowledgeType: [
          {required: true, message: "请选择知识分类", trigger: "change"},
        ],
        fileType: [
          {required: true, message: "请选择文件分类", trigger: "change"},
        ],
        scopeApplication: [
          {required: true, message: "请选择适用范围", trigger: "change"},
        ],
        scopeDept: [
          {required: true, message: "请选择适用部门", trigger: "change"},
        ],
        deptIdList: [
          {required: true, message: "请选择部门", trigger: "change"},
        ],
      },
      selectNode: {},
      showFileDialog: false,
      fileUrl: "",
      type: "",
      showImg: true, //默认展示
      noneUploadImg: false, //
      fileList: [],
      limitCountImg: 1,
      deptTreeData: [],
      defaultProps: {
        label: "title",
        value: "id",
        key: "id",
      },
      defaultChecked: [],
      inputTreeProps: {
        label: "classifyName",
        value: "id",
      },
      dic: [],
      showClassification: false,
      deptProps: {
        label: "deptName",
        value: "id",
      },
      deptDic: [],
    };
  },
  mixins: [exForm, draft],
  computed: {
    ...mapGetters(["tag", "userInfo"]),
    headers: function () {
      return {"Sinoma-Auth": getToken()};
    },
    leftBtnOptions() {
      return [
        {
          label: "保存",
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
        },
      ]
    },
    headBtnOptions() {
      let headBtn = [];
      // headBtn.push({
      //   label: "智能总结",
      //   emit: "head-summary",
      // });
      if (["admin"].includes(this.type)) {
        headBtn.push(
          {
            label: "保存",
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: "save",
          },
        );
      }
      if (["add", "edit", "workflow"].includes(this.type) && (this.form.status === 1 || this.form.status === 4)) {
        headBtn.push(
          {
            label: "保存",
            emit: "head-save",
            type: "button",
            icon: "",
            btnOptType: "save",
          },
        );
      }
      if (["add", "edit"].includes(this.type)) {
        headBtn.push(
          {
            label: "提交",
            emit: "head-submit",
            type: "button",
            icon: "",
            btnOptType: "submit",
          },
        );
      }
      if (!["workflow"].includes(this.type)) {
        headBtn.push({
          label: "返回",
          emit: "head-back",
          type: "button",
          icon: "",
          btnOptType: "back",
        });
      }
      return headBtn;
    },
  },
  created() {
    this.initParams()
    let url = window.location.href;
    let p = url.split("?")[1];
    let id = "";
    let type = "view";
    if (p) {
      let query = new URLSearchParams(p);
      id = query.get("id");
      type = query.get("type");
    } else {
      id = this.$route.query.id;
      type = this.$route.query.type;
    }
    this.type = type;
    if (this.$route.query.fileList) {
      this.treeData = JSON.parse(this.$route.query.fileList)
    }
    this.getTreeData();
    if (["edit", "view", "workflow", "admin"].includes(this.type)) {
      this.echo = "yes"
      this.getFiles(id);
    }
    this.readOnly = ["view", "workflow"].includes(this.type);
    this.initTree();
    this.initDeptTree();
  },
  mounted() {
    this.getProcessId(this.page, {});
  },

  methods: {
    initParams() {
      list().then(res => {
        let arr = res.data.data.records
        localStorage.setItem("txtBgi", arr.find(e => e.paramType == "txtFileCover").paramValue)
        localStorage.setItem("zipBgi", arr.find(e => e.paramType == "zipFileCover").paramValue)
      })
    },
    initDeptTree() {
      selectParentTree().then((result) => {
        this.deptDic = result.data.data;
        if (this.readOnly) {
          this.setDisabled(this.deptDic);
        }
      })
    },
    initTree() {
      getKnowledgeClassifyTree()
        .then((result) => {
          this.dic = result.data.data;
        })
    },
    chooseKnowledge(e) {
      if (e) {
        this.showClassification = true;
        this.form.knowledgeType = e;
        this.$nextTick(() => {
          this.$refs.knowledgeClass.showList = [];
          if (["edit", "view", "workflow", "admin"].includes(this.type)) {
            let fileTypeIdList = this.form.fileTypeId.split(",");
            let fileTypeList = this.form.fileType.split(",");
            let dataListFileType = []
            for (let i = 0; i <= fileTypeIdList.length; i++) {
              let cc = {
                id: fileTypeIdList[i],
                cateLineCode: fileTypeList[i]
              }
              dataListFileType.push(cc)
            }
            this.selectTag(dataListFileType);
          } else {
            this.$refs.knowledgeClass.selectTags = [];
            this.selectTag(this.$refs.knowledgeClass.selectTags);
          }
          this.$refs.knowledgeClass.getList(e);
        })
      }
    },
    setDisabled(obj) {
      obj.forEach((item) => {
        this.$set(item, "disabled", true);
        if (item.hasChildren) {
          this.setDisabled(item.children);
        }
      });
    },
    getTreeData() {
      selectChildren().then((res) => {
        if (res.data.code == 200) {
          this.deptTreeData = res.data.data;
          if (this.readOnly) {
            this.setDisabled(this.deptTreeData);
          }
        }
      });
    },
    handleCheckChange(data, checked) {
      let checkedKeys = this.$refs.deptTree.getCheckedKeys();
      this.form.deptIdList = checkedKeys;
    },
    showFile(item) {
      this.fileUrl =
        process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
        encodeURIComponent(Base64.encode(item.link));
      this.showFileDialog = true;
      //excels表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;

        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    handleRemove(file) {
      removeUploadFile(file.attachId).then((res) => {
        this.treeData = this.treeData.filter((item) => item.id !== file.attachId);
      });
    },
    handleFileCoverSuccess(res, file) {
      this.form.fileCover = file.response.data.link;
      this.$refs.form.validateField('fileCover');
    },
    fileIds(res) {
      this.form.fileCover = res;
      this.$refs.form.validateField('fileCover');
    },
    // 判断数量，是否显示上传icon
    uploadImgChange(file, fileList) {
      this.noneUploadImg = fileList.length >= this.limitCountImg;
    },
    // 删除图片，判断数量，是否显示icon
    handleDeleteImgRemove() {
      this.fileList = [];
      this.noneUploadImg = this.fileList.length >= this.limitCountImg;
      this.form.fileCover = "";
      this.$refs.form.validateField('fileCover');
    },
    dataFromat(list) {
      let a = []
      list.forEach(e => {
        a.push(this.fun2(e))
      })
    },
    formatTime(seconds) {
      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds % 3600) / 60);
      var remainingSeconds = seconds % 60;

      // 将时、分、秒转换为两位数的格式
      var hoursStr = this.padZero(hours);
      var minutesStr = this.padZero(minutes);
      var secondsStr = this.padZero(remainingSeconds);

      return hoursStr + ":" + minutesStr + ":" + secondsStr;
    },
    padZero(num) {
      return (num < 10 ? "0" : "") + num;
    },
    fun2(e) {
      return new Promise(resolve => {
        let that = this;
        var video = document.createElement('video');
        video.src = e.link;
        video.addEventListener('loadedmetadata', function () {
          var seconds = video.duration;
          e.duration = that.formatTime(Math.floor(seconds));
          resolve(that.treeData)
        })
      })

    },
    base64ToFile(base64Data, filename) {
      // 将base64的数据部分提取出来
      const parts = base64Data.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);

      // 将原始数据转换为Uint8Array
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);
      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      // 使用Blob和提取出的数据内容创建一个新的Blob对象
      const blob = new Blob([uInt8Array], {type: contentType});

      // 创建一个指向Blob对象的URL，并使用这个URL创建一个File对象
      const blobUrl = URL.createObjectURL(blob);
      const file = new File([blob], filename, {type: contentType});

      // 返回File对象
      return file;
    },
    handleAvatarSuccess(file) {
      let type = file.raw.type
      let name = file.raw.name
      let suffix = ''
      try {
        let fileArr = name.split('.')
        suffix = fileArr[fileArr.length - 1]
      } catch (e) {
        suffix = ''
      }
      if (suffix == "mp4") {
        this.videoUrl = file.response.path;
        let that = this
        this.$nextTick(() => {
          const video = document.getElementById('myVideo');
          const canvas = document.getElementById('myCanvas');
          const ctx = canvas.getContext('2d');
          video.addEventListener('loadeddata', function () {
            video.currentTime = 3;
            setTimeout(() => {
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
              const imageURL = canvas.toDataURL('image/png');
              const formData = new FormData();
              // console.log("进行输出-----------1",imageURL)
              formData.append('file', that.base64ToFile(imageURL, Date.now() + '.png'));
              platform(formData).then((res) => {
                that.form.fileCover = res.data.data.link
                that.fileList = [{path: res.data.data.link}]
                if (that.form.fileCover) that.noneUploadImg = true
                that.$refs.form.validateField('fileCover');
                console.log(that.form.fileCover)
              })
            }, 1000)
          });
        });
      }
      let matchArr = ['doc', 'docx', 'pdf', 'xls', 'xlsx', 'ppt', 'pptx']
      if (type.indexOf('image') >= 0) {
        this.form.fileCover = file.response.path
        this.fileList = [{path: file.response.path}]
        if (this.form.fileCover) this.noneUploadImg = true
        this.$refs.form.validateField('fileCover');
      } else if (matchArr.indexOf(suffix) > -1) {
        this.$loading()
        generateCover(file.response.id).then(res => {
          this.form.fileCover = res.data.data
          this.fileList = [{path: res.data.data}]
          if (this.form.fileCover) this.noneUploadImg = true
          this.$refs.form.validateField('fileCover');
          console.log(this.form)
          this.$loading().close()
        })

      } else if (suffix == 'txt') {
        let txtBgi = localStorage.getItem("txtBgi");
        if (!txtBgi) this.initParams()
        this.form.fileCover = localStorage.getItem("txtBgi");
        this.fileList = [{path: localStorage.getItem("txtBgi")}]
        if (this.form.fileCover) this.noneUploadImg = true
        this.$refs.form.validateField('fileCover');
      } else if (['zip', 'rar', '7z'].indexOf(suffix) > -1) {
        let zipBgi = localStorage.getItem("zipBgi");
        if (!zipBgi) this.initParams()
        this.form.fileCover = localStorage.getItem("zipBgi");
        this.fileList = [{path: localStorage.getItem("zipBgi")}]
        if (this.form.fileCover) this.noneUploadImg = true
        this.$refs.form.validateField('fileCover');
      }
      let link = file.response.path;
      let extension = link.split('.').pop();
      this.fileSuffixType = extension
      this.treeData.push({
        extension: extension,
        id: file.response.attachId,
        link: link,
        isActive: true,
        duration: 0
      });
      // this.fileOne = file
      if (file.raw.type !== '.mp4' &&
        file.raw.type !== '.avi' &&
        file.raw.type !== '.mkv' &&
        file.raw.type !== '.mov' &&
        file.raw.type !== '.wmv' &&
        file.raw.type !== '.flv' &&
        file.raw.type !== '.mpeg') {
        this.dataFromat(this.treeData)
      }
      this.selectNode = this.treeData[0]
      if (!this.form.fileName) {
        this.form.fileName = file.name
      }
      this.$message({
        type: "success",
        message: "上传成功！",
      });
    },
    beforeUpload(file) {

      if (this.treeData.length > 0) {
        this.$message({
          type: "warning",
          message: "请先删除文件后再上传！",
        });
        return false;
      }
      this.fileOne = file
      // const fileName = file.name;
      // const fileType = fileName.substring(fileName.lastIndexOf('.'));
      // if (fileType !== '.jpg' && fileType !== '.jpeg' && fileType !== '.png' && fileType !== '.pdf' && fileType !== '.excel' && fileType !== '.word' && fileType !== '.doc' && fileType !== '.docx' && fileType !== '.xls' && fileType !== '.xlsx' && fileType !== '.txt') {
      //   this.$message({
      //     type: "warning",
      //     message: "请上传jpg、jpeg、png、pdf、excel、word、doc、docx、xls、xlsx、txt类型文件！",
      //   });
      //   return false;
      // }
      // const isLt20M = file.size / 1024 / 1024 < 20;
      // if (!isLt20M) {
      //   this.$message.warning('上传文件大小不能超过 20MB!');
      // }
      // return isLt20M;
      return true;
    },
    beforeAvatarUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      const isJPG = (file.type === 'image/jpeg' || file.type === 'image/jpg'
        || file.type === 'image/gif' || file.type === 'image/png');

      if (!isJPG) {
        this.$message.warning('上传图片只能是 自定义文件 格式!');
      }
      if (!isLt20M) {
        this.$message.warning('上传文件大小不能超过 20MB!');
      }
      return isJPG && isLt20M;
    },
    getFiles(id) {
      getDetail(id).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data;
          try {
            this.$refs.singleFIleUpload.assignedPicture(data.attachList[0])
          } catch (e) {
            console.log(e)
          }
          this.fileSuffixType = data.fileSuffixType;
          data.fileTag = data.fileTag.split(",");
          this.form = data;
          this.defaultChecked = this.form.deptIdList;
          if (this.form.fileCover) {
            this.noneUploadImg = true;
            this.fileList = [
              {
                url: this.form.fileCover,
              },
            ];
          } else {
            this.fileList = [];
          }
          this.readOnly = (["view", "workflow"].includes(this.type) && (this.form.status === 2 || this.form.status === 3));
          this.treeData = data.attachList;
          if (this.treeData.length > 0) {
            this.treeData.forEach((item) => {
              item.isActive = true;
            });
            this.selectNode = this.treeData[0];
          }
        }
      });
    },
    getNodeClick(node) {
      node.isActive = true;
      this.treeData.forEach((item) => {
        if (item.id !== node.id) {
          item.isActive = false;
        }
      });
      this.selectNode = node;
      this.showFile(node);
    },
    getProcessId(page, params = {}) {
      this.submitLoading = true;
      this.page = page;
      getProcessList(page.currentPage, page.pageSize, params).then((res) => {
        let process = res.data.data.records.filter(
          (item) => item.key == this.processTemplateKey
        )[0];
        this.processId = process.id;
        this.getForm(this.processId, process.name);
      });
    },

    getForm(processId, name) {
      this.getStartForm(processId).then(() => {
        // let {process, startForm} = res
        // 自行处理相关逻辑
        this.tag.label = name;
        this.submitLoading = false;
      });
    },

    handleClose(tag) {
      this.form.fileTag.splice(this.form.fileTag.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.form.fileTag.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    selectTag(list) {
      let fileType = [];
      let fileTypeId = [];
      if (list.length > 0) {
        list.forEach((item) => {
          fileType.push(item.cateLineCode);
          fileTypeId.push(item.id);
        });
      }
      this.form.fileType = fileType.toString();
      this.form.fileTypeId = fileTypeId.toString();
      this.$refs.form.validateField('fileType');
    },
    headSummary() {
      if (this.treeData.length == 0) {
        this.$message({
          type: "warning",
          message: "请在左侧附件列表上传附件",
        });
        return;
      }
      if (!this.selectNode.id) {
        this.$message({
          type: "warning",
          message: "请选择左侧附件列表附件",
        });
        return;
      }
      let fileCover = this.treeData[0].link
      if (this.treeData[0].link.indexOf(".mp4") != -1) {
        console.log(this.fileList)
        if(this.fileList.length==0 && !Boolean(this.form.fileCover)){
          this.$message({
            type: "warning",
            message: "请上传视频封面",
          });
          return;
        }
        fileCover = (this.fileList[0] && this.fileList[0].path) || this.form.fileCover;

      }

      let formData = new FormData()

      console.info("file-->" + fileCover)
      // debugger
      formData.append('file', fileCover)
      console.log(this.fileOne)
      console.log(formData, 'formdata')
      this.$loading();
      intelligentSummary(formData).then((res) => {
        console.log(res)
        if (res.data.data) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.form.fileDesc = res.data.data.summary;
          if (res.data.data.fileTag) this.form.fileTag = res.data.data.fileTag.split(",");
          // this.initTree();
        }
      }).finally(() => {
        this.$loading().close();
      })
    },
    headSave() {
      if (this.treeData.length == 0) {
        this.$message({
          type: "warning",
          message: "请在左侧附件列表上传附件",
        });
        return;
      }
      if (!this.selectNode.id) {
        this.$message({
          type: "warning",
          message: "请选择左侧附件列表附件",
        });
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          let data = JSON.parse(JSON.stringify(this.form));
          data.attachIds = this.selectNode.id;
          data.extension = this.fileSuffixType;
          data.fileTag = data.fileTag.toString();
          data.fileSuffixType = this.fileSuffixType;
          data.duration = this.selectNode.duration;
          saveUpload(data).then((res) => {
            if (res.data.code == 200) {
              let data = res.data.data;
              data.fileTag = data.fileTag.split(",");
              this.form = data;
              this.$message({
                type: "success",
                message: "保存成功",
              });
              if (this.type !== "workflow") {
                this.$router.$avueRouter.closeTag();
                //保存不需要返回到上个页面
                // this.$router.push("/knowledge/myKnowledgeBase");
              } else {
                window.parent.postMessage({
                  key: "knowledgeUpload",
                  processBusinessName: data.fileName,
                }, '*')
              }
            }
          }).finally(() => {
            this.$loading().close();
          })
        }
      });
    },
    headSubmit() {
      if (this.treeData.length == 0) {
        this.$message({
          type: "warning",
          message: "请上传附件",
        });
        return;
      }
      if (!this.selectNode.id) {
        this.$message({
          type: "warning",
          message: "请选择附件",
        });
        return;
      }
      this.submitLoading = true;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          let data = JSON.parse(JSON.stringify(this.form));
          data.attachIds = this.selectNode.id;
          data.fileTag = data.fileTag.toString();
          data.fileSuffixType = this.fileSuffixType;
          data.status = 2;
          data.duration = this.selectNode.duration;
          saveUpload(data).then((res) => {
            if (res.data.code == 200) {
              let dataRes = res.data.data;
              dataRes.fileTag = dataRes.fileTag.split(",");
              this.form = dataRes;
              this.form.processId = this.processId;
              this.form.deptId = this.userInfo.dept_id;
              this.form.processBusinessName = this.form.fileName;
              this.handleStartProcess5(true).then((res) => {
                this.form.processInstanceId = res.data.data;
                this.form.fileTag = this.form.fileTag.join(',');
                saveUpload(this.form);
                this.$message.success(
                  this.$t("cip.cmn.msg.success.operateSuccess")
                );
                this.$router.$avueRouter.closeTag();
                this.$router.push("/knowledge/myKnowledgeBase");
                this.$loading().close();
              });
            }
          })
        }
      });
    },
    headBack() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
  },
};
</script>
<style>
.disUoloadSty .el-upload--picture-card {
  display: none;
}
</style>
<style scoped lang='scss'>
.left-content {
  width: 188px;
  box-sizing: border-box;
  border-right: 1px solid #cccccc;
  background: #fff;

  ::v-deep .head-layout .headBtn {
      opacity: 0;
  }

  ::v-deep .head-layout .headBtn button {
    cursor: auto;
  }

  .img-container {
    overflow-y: auto;
    height: calc(100vh - 226px);

    .img-content {
      padding: 3px;
      position: relative;

      .img-item {
        height: 100px;
        padding: 3px;

        img {
          width: 100%;
          height: 100%;
        }

        span {
          width: 100%;
          height: 100%;
          background: #ccc;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .delete-icon {
          position: absolute;
          top: 3px;
          right: 3px;
          font-size: 16px;
          color: red;
        }
      }
    }

    .isActive {
      border: 2px solid red;
      border-radius: 3px;
    }
  }
}

.common_tree_handle {
  min-width: 188px;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

::v-deep .el-form-item__content {
  .el-tag {
    height: 32px;
    line-height: 32px;
    margin-right: 12px;
    margin-bottom: 5px;

    .el-icon-close {
      font-size: 16px;
    }
  }
}
</style>
